import * as React from 'react'
import Axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import {
  Alert,
  CssBaseline,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'

import logo from './assets/logo.png'
import './App.css'

const axios = Axios.create({ baseURL: process.env.REACT_APP_SERVER_BASE_URL })

function App() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inUse, setInUse] = useState(null)

  const handleEmailCheck = () => {
    setLoading(true)
    axios
      .get(`/admin/go2-members/email-in-use/${email}@go2.io/`)
      .then((resp) => {
        setInUse(resp.data.in_use)
      })
      .catch((err) => {
        if (err.response?.data?.email) {
          setError(err.response?.data?.email)
        }
      })
      .finally(() => setLoading(false))
  }

  const getMessage = () => {
    if (inUse === null) return null
    if (inUse === true) {
      return `I'm sorry, ${email}@go2.io is taken.`
    }
    return `Hooray! ${email}@go2.io is available.`
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{ padding: '10px' }}
        marginTop="20vh"
      >
        <Grid item display="flex" justifyContent="center" xs={12}>
          <img src={logo} alt="logo" style={{ maxWidth: '150px' }} />
        </Grid>
        {inUse !== null && (
          <Grid item display="flex" justifyContent="center" xs={12}>
            <Alert severity={inUse === true ? 'error' : 'success'}>
              {getMessage()}
            </Alert>
          </Grid>
        )}

        <Grid
          item
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          xs={12}
          sm={5}
          xl={4}
        >
          <TextField
            autoFocus
            fullWidth
            required
            label="Email"
            placeholder="Check if the email you want is still available"
            type="email"
            size="small"
            value={email}
            error={!!error}
            helperText={
              error?.join(' | ') ?? 'Just type the name before the @ symbol'
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">@go2.io</InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === '@') e.preventDefault()
            }}
            onKeyUp={(e) => {
              if (e.key.toLocaleLowerCase() !== 'enter') return
              if (!email) return
              handleEmailCheck()
            }}
            onChange={(e) => {
              setInUse(null)
              setError(null)
              setEmail(e.target.value)
            }}
          />
          <LoadingButton
            disableElevation
            variant="contained"
            onClick={handleEmailCheck}
            loading={loading}
            sx={{ marginLeft: '5px', height: '40px' }}
            disabled={!email}
          >
            Check
          </LoadingButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default App
